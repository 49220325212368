@charset "utf-8";
@import "compass/compass";
@import "mixin";

/*リセット
--------------------------------------------*/
@include global-reset;

/*フォント
---------------------------------------------*/

// @include font-face('noto-sans','../fonts/NotoSansCJKjp-DemiLight',350);
// @include font-face('noto-sans','../fonts/NotoSansCJKjp-Bold',700);

/*メディアクエリー
---------------------------------------------*/
$breakpoints: (
  'xs': 'screen and (min-width: 400px)',
  'sp': 'screen and (max-width: 768px)',
  'pc': 'screen and (min-width: 769px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

/*変数
---------------------------------------------*/
$pcFontXs: 10px;
$pcFontS: 12px;
$pcFontM: 14px;
$pcFontL: 22px;
$pcSpace1: 19px;
$pcSpace2: 38px;
$pcSpace3: 57px;
$red: #d45657;
$gray: #777;
$white: #fff;
$spFontS: 10px;
$spFontM: 14px;
$spFontL: 16px;


/* コンテンツ
---------------------------------------------*/
html {
  width: 100%;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 18px;
}

body {
  background-image: none;
  width: 100%;
  height: 100%;
  overflow-x:hidden;
  }

img{
  width: 100%;
  height: auto;
  display: block;
}

a {
  color: #000;
  text-decoration: none;
}

.pcNone{
  @include mq(pc){
    display: none;
  }
  @include mq(sp){
    display: block;
  }
}
.spNone{
  @include mq(pc){
    display: block;
  }
  @include mq(sp){
    display: none;
  }
}

#stalker {
  pointer-events: none;
  position: fixed;
  top: -90px; //座標調節（カーソル位置と円の中心を合わせる）
  left: -90px; //座標調節（カーソル位置と円の中心を合わせる）
  width: 20%; //マウスストーカーの直径
  height: 20%; //マウスストーカーの直径
  // background: rgba(0, 0, 0, 0.5);
  // border-radius: 50%;
  transform: translate(0, 0);
  transition: transform 0.1s;
  transition-timing-function: ease-out;
  z-index: 999;
  background-image: url(../images/yaji.svg);
  background-repeat: no-repeat;
}
#stalker02 {
  pointer-events: none;
  position: fixed;
  top: -120px; //座標調節（カーソル位置と円の中心を合わせる）
  left: -120px; //座標調節（カーソル位置と円の中心を合わせる）
  width: 20%; //マウスストーカーの直径
  height: 20%; //マウスストーカーの直径
  transform: translate(0, 0);
  transition: transform 0.1s;
  transition-timing-function: ease-out;
  z-index: 999;
  background-image: url(../images/yaji02.svg);
  background-repeat: no-repeat;
  }

.remove {
  display: none;
}


#wrap {
  width: 100%;
  background-color: #fff;
}

#main_movie {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  background: #000;
  margin: 0 auto;
  text-align: center;

  nav {
    background: #000;
    position: absolute;
    z-index: 100;
    bottom: 7%;
    right: 4%;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    border: solid 2px #000;
    padding: 10px;
    box-sizing: border-box;

    a {
      color: #fff;

    }
    a:hover {
      color: #ffeb00;
    }
  }
}

// ページ遷移アニメ
.fade_group div,
.fade_group2 div {
  content: '';
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100%;
  pointer-events: none;
}

.fade_group div {
  z-index: 9999;
  transition: .6s;
}

.fade_group2 div {
  z-index: 9998;
  transition: .8s;
}

.fade_group.fade_01 div:nth-of-type(1) {
  background: rgba(0, 153, 209, .3);
}
.fade_group.fade_01 div:nth-of-type(2) {
  background: rgba(0, 153, 209, .5);
  transition-delay: .1s;
}
.fade_group.fade_01 div:nth-of-type(3) {
  background: rgba(0, 153, 209, .7);
  transition-delay: .2s;
}
.fade_group.fade_01 div:nth-of-type(4) {
  background: rgba(0, 153, 209, 1);
  transition-delay: .3s;
}
.fade_group2.fade_01 div:nth-of-type(1) {
  background: rgba(0, 153, 209, .8);
  transition-delay: .2s;
}
.fade_group2.fade_01 div:nth-of-type(2) {
  background: rgba(0, 153, 209, .8);
  transition-delay: .3s;
}

.fade_group.fade_02 div:nth-of-type(1) {
  background: rgba(245, 205, 49, .3);
}
.fade_group.fade_02 div:nth-of-type(2) {
  background: rgba(245, 205, 49, .5);
  transition-delay: .1s;
}
.fade_group.fade_02 div:nth-of-type(3) {
  background: rgba(245, 205, 49, .7);
  transition-delay: .2s;
}
.fade_group.fade_02 div:nth-of-type(4) {
  background: rgba(245, 205, 49, 1);
  transition-delay: .3s;
}
.fade_group2.fade_02 div:nth-of-type(1) {
  background: rgba(245, 205, 49, .8);
  transition-delay: .2s;
}
.fade_group2.fade_02 div:nth-of-type(2) {
  background: rgba(245, 205, 49, .8);
  transition-delay: .3s;
}

.fade_group.fade_03 div:nth-of-type(1) {
  background: rgba(75, 185, 83, .3);
}
.fade_group.fade_03 div:nth-of-type(2) {
  background: rgba(75, 185, 83, .5);
  transition-delay: .1s;
}
.fade_group.fade_03 div:nth-of-type(3) {
  background: rgba(75, 185, 83, .7);
  transition-delay: .2s;
}
.fade_group.fade_03 div:nth-of-type(4) {
  background: rgba(75, 185, 83, 1);
  transition-delay: .3s;
}
.fade_group2.fade_03 div:nth-of-type(1) {
  background: rgba(75, 185, 83, .8);
  transition-delay: .2s;
}
.fade_group2.fade_03 div:nth-of-type(2) {
  background: rgba(75, 185, 83, .8);
  transition-delay: .3s;
}

.fade_group.fade_04 div:nth-of-type(1) {
  background: rgba(232, 66, 82, .3);
}
.fade_group.fade_04 div:nth-of-type(2) {
  background: rgba(232, 66, 82, .5);
  transition-delay: .1s;
}
.fade_group.fade_04 div:nth-of-type(3) {
  background: rgba(232, 66, 82, .7);
  transition-delay: .2s;
}
.fade_group.fade_04 div:nth-of-type(4) {
  background: rgba(232, 66, 82, 1);
  transition-delay: .3s;
}
.fade_group2.fade_04 div:nth-of-type(1) {
  background: rgba(232, 66, 82, .8);
  transition-delay: .2s;
}
.fade_group2.fade_04 div:nth-of-type(2) {
  background: rgba(232, 66, 82, .8);
  transition-delay: .3s;
}

.fade_group.fade_05 div:nth-of-type(1) {
  background: rgba(59, 208, 174, .3);
}
.fade_group.fade_05 div:nth-of-type(2) {
  background: rgba(59, 208, 174, .5);
  transition-delay: .1s;
}
.fade_group.fade_05 div:nth-of-type(3) {
  background: rgba(59, 208, 174, .7);
  transition-delay: .2s;
}
.fade_group.fade_05 div:nth-of-type(4) {
  background: rgba(59, 208, 174, 1);
  transition-delay: .3s;
}
.fade_group2.fade_05 div:nth-of-type(1) {
  background: rgba(59, 208, 174, .8);
  transition-delay: .2s;
}
.fade_group2.fade_05 div:nth-of-type(2) {
  background: rgba(59, 208, 174, .8);
  transition-delay: .3s;
}

.fade_group.fade_06 div:nth-of-type(1) {
  background: rgba(195, 230, 0, .3);
}
.fade_group.fade_06 div:nth-of-type(2) {
  background: rgba(195, 230, 0, .5);
  transition-delay: .1s;
}
.fade_group.fade_06 div:nth-of-type(3) {
  background: rgba(195, 230, 0, .7);
  transition-delay: .2s;
}
.fade_group.fade_06 div:nth-of-type(4) {
  background: rgba(195, 230, 0, 1);
  transition-delay: .3s;
}
.fade_group2.fade_06 div:nth-of-type(1) {
  background: rgba(195, 230, 0, .8);
  transition-delay: .2s;
}
.fade_group2.fade_06 div:nth-of-type(2) {
  background: rgba(195, 230, 0, .8);
  transition-delay: .3s;
}

.fade_group.fade_07 div:nth-of-type(1) {
  background: rgba(151, 37, 234, .3);
}
.fade_group.fade_07 div:nth-of-type(2) {
  background: rgba(151, 37, 234, .5);
  transition-delay: .1s;
}
.fade_group.fade_07 div:nth-of-type(3) {
  background: rgba(151, 37, 234, .7);
  transition-delay: .2s;
}
.fade_group.fade_07 div:nth-of-type(4) {
  background: rgba(151, 37, 234, 1);
  transition-delay: .3s;
}
.fade_group2.fade_07 div:nth-of-type(1) {
  background: rgba(151, 37, 234, .8);
  transition-delay: .2s;
}
.fade_group2.fade_07 div:nth-of-type(2) {
  background: rgba(151, 37, 234, .8);
  transition-delay: .3s;
}

body.fo .fade_group div {
  left: 0;
}

body.fo .fade_group2 div {
  left: 100vw;
}


// flame set
#flame_area_right {
    background: #fff;
    width: 80px;
    height: 100%;
    border-left: solid 8px;
    border-top: solid 8px;
    border-bottom: solid 8px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 10001;
    right: 0;
    img {
    width: 30px;
    margin: auto;
    top: 43%;
    position: relative;
    }
}

#flame_area_top {
  position: fixed;
  top: 0;
  background: #fff;
  width: 100%;
  height: 80px;
  border-top: solid 8px;
  border-bottom: solid 8px;
  padding: 22px 40px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  box-sizing: border-box;
  z-index: 10000;
  span.slash_1 {
margin-left: 230px;
  }
  span.slash_2 {
margin-left: 153px;
  }

  .menu {
  width: 250px;
  position: fixed;
  z-index: 100;
  height: 80px;

  .button-effect {
    text-align: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 32px;
  }

  a.effect {
    width: 250px;
    text-align: center;
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #000;
    // text-transform: capitalize;
    overflow: hidden;
    transition: all 0.2s linear 0s;
    padding: 5px;
    box-sizing: border-box;

    &:hover {
      text-indent: -9999px;

      &:before {
        transform: scale(1, 1);
        text-indent: 0;
      }
    }
  }
}

.menu_1 {
  width: 250px;
  top: 0;
  margin: auto;
  left: 20px;

  a.effect {
    &:before {
      content: "リプロとは？";
      color: red;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      text-align: center;
      transform: scale(1, 0);
      transition: all 0.2s linear 0s;
    }
  }
}

.menu_2 {
  width: 250px;
  top: 0;
  left: 235px;

  a.effect {
    &:before {
      content: "プロダクト一覧";
      color: blue;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      text-align: center;
      transform: scale(1, 0);
      transition: all 0.2s linear 0s;
    }
  }
}

.menu_3 {
  width: 250px;
  top: 0;
  left: 403px;

  a.effect {
    &:before {
      content: "店舗紹介";
      color: green;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      text-align: center;
      transform: scale(1, 0);
      transition: all 0.2s linear 0s;
    }
  }
}

}

// btn
.container {
  width: 350px;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 80px;

  .button-effect {
    text-align: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 32px;
  }

  a.effect {
    width: 350px;
    text-align: center;
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #000;
    text-transform: capitalize;
    overflow: hidden;
    transition: all 0.2s linear 0s;
    padding: 5px;
    box-sizing: border-box;

    &:before {
      content: "Go to Campfire →";
      color: #FFF;
      background: red;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 20px;
      transform: scale(0, 1);
      transition: all 0.2s linear 0s;
    }

    &:hover {
      text-indent: -9999px;
      &:before {
        transform: scale(1, 1);
        text-indent: 0;
      }
    }
  }
}
// btn

#flame_area_bottom {
  width: 100%;
  height: 80px;
  border-top: solid 8px;
  border-bottom: solid 8px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 10000;

  p.arrow {
    font-size: 35px;
    text-align: center;
    width: 80px;
    background: #000;
    height: 66px;
    padding: 15px 0px;
    box-sizing: border-box;
    a {
      color: #fff;
    }
    a:hover {
      text-decoration: none;
      color: #ffeb00;
    }
  }
}
// flame set


// page_top
.top {

  .main_ttl {
    position: absolute;
    bottom: 20px;
    left: 20px;
        z-index: 10;
  }

  .img_box {
    width: 100%;
    // height: calc(100vh - 80px);
    .top_img {
      object-fit: cover;
      height: 100vh;
      width: 100%;
    }
  }

li.item01 {
    .main_ttl {
    width: auto;
    height: 83%;
    }

    .img_box {
      .flame_left {
            width: 80px;
            background: #0099d1;
            position: absolute;
            height: 100%;
      }
            .flame_bottom {
              width: 100%;
              height: 80px;
              background: #0099d1;
              position: absolute;
              bottom: 0;
            }
    }
  }

li.item02 {
    .main_ttl {
    width: auto;
    height: 80%;
    }

        .img_box {
          .flame_left {
            width: 80px;
            background: #f5cd31;
            position: absolute;
            height: 100%;
          }

          .flame_bottom {
            width: 100%;
            height: 80px;
            background: #f5cd31;
            position: absolute;
            bottom: 0;
          }
        }
  }

li.item03 {
    .main_ttl {
    width: auto;
    height: 80%;
    }
          .img_box {
            .flame_left {
              width: 80px;
              background: #4bb953;
              position: absolute;
              height: 100%;
            }

            .flame_bottom {
              width: 100%;
              height: 80px;
              background: #4bb953;
              position: absolute;
              bottom: 0;
            }
          }
}

li.item04 {
  .main_ttl {
    width: auto;
    height: 80%;
  }

  .img_box {
    .flame_left {
      width: 80px;
      background: #e84252;
      position: absolute;
      height: 100%;
    }
    .flame_bottom {
      width: 100%;
      height: 80px;
      background: #e84252;
      position: absolute;
      bottom: 0;
    }
  }
}

li.item05 {
  .main_ttl {
    width: 85%;
  }

  .img_box {
    .flame_left {
      width: 80px;
      background: #3BD0AE;
      position: absolute;
      height: 100%;
    }

    .flame_bottom {
      width: 100%;
      height: 80px;
      background: #3BD0AE;
      position: absolute;
      bottom: 0;
    }
  }
}

li.item06 {
  .main_ttl {
    width: auto;
    height: 75%;
  }

  .img_box {
    .flame_left {
      width: 80px;
      background: #c3e600;
      position: absolute;
      height: 100%;
    }

    .flame_bottom {
      width: 100%;
      height: 80px;
      background: #c3e600;
      position: absolute;
      bottom: 0;
    }
  }
}

li.item07 {
  .main_ttl {
    width: auto;
    height: 75%;
  }

  .img_box {
    .flame_left {
      width: 80px;
      background: #9725EA;
      position: absolute;
      height: 100%;
    }

    .flame_bottom {
      width: 100%;
      height: 80px;
      background: #9725EA;
      position: absolute;
      bottom: 0;
    }
  }
}
}
//page_top


// page_about
.about {
text-align: center;
margin: 230px auto;

.main_text{
    width: calc(100% - 80px);

.typing {
  display: flex;
  justify-content: center;
  // background: linear-gradient(transparent 40%, #ffeb00 0%);
  width: 520px;
  margin: auto;
  margin-top: 150px;
  position: relative;
}

.typing p {
  color: #000;
  font-size: 1.6em;
  font-weight: 500;
  letter-spacing: 50px;
  overflow: hidden;
  white-space: nowrap;
  border-right: 10px solid;
  position: relative;
      z-index: 10;
}

.typing .hello {
  width: 0;
  animation:
    type 2.0s steps(13, end) 1500ms forwards;
}

@keyframes type {
  to {
    width: 490px;
  }
}

.background {
  width: 530px;
  height: 30px;
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0) 50%, #FFEB00 0%);
  background-size: 0%;
  background-repeat: no-repeat;
  animation: drawTheLine .5s linear 1 forwards;
  animation-delay: 3.7s;
}

@keyframes drawTheLine {
  0% {
    background-size: 0% 100%;
    -ms-background-size: 0% 100%;
  }

  100% {
    background-size: 100% 100%;
    -ms-background-size: 100% 100%;
  }
}

}

p.text_1 {
    font-size: 40px;
    letter-spacing: 4px;
}
.logo {
  width: 520px;
  margin: 50px auto 0;
}


.repro_text {
background: #000;
color: #fff;
width: 80%;
margin: 20% auto;
padding: 50px;
box-sizing: border-box;
  font-family: "Hiragino Kaku Gothic ProN";
font-size: 20px;
line-height: 30px;
text-align: left;
letter-spacing: 2px;
span.marker_1 {
    background: linear-gradient(transparent 0%, #ffeb00 0%);
    padding: 2px 0 2px 10px;
    color: #000;
    font-weight: bold;
}

span.marker_2 {
  color: #ffeb00;
  font-weight: bold;
}

p.insta {
  text-align: right;
  a {
    color: #ffeb00;
    &:hover {
      color: red;
    }
  }
}

}

.about_ttl {
  position: relative;
  height: 70px;
}

p.about_ttl_text {
  position: absolute;
  text-align: left;
  color: #fff;
  width: 90%;
  font-size: 50px;
  box-sizing: border-box;
    animation: colorChange 1.7s ease-out 1 forwards;
    // animation-delay: 1.0s;
    }
    .ttl_1 {
      p.about_ttl_text {
        padding: 7px 80px 14px;
      }
    }
    .ttl_2 {
      p.about_ttl_text {
        padding: 9px 80px 14px;
      }
    }

    @keyframes colorChange {
      0% {
  color: #fff;
      }

      50% {
  color: #fff;
      }

      100% {
  color: #000;
      }
    }

.background_line {
  position: absolute;
  background: #000;
  height: 70px;
  width: 0%;
        border-top: solid 5px #000;
        border-bottom: solid 5px #000;
        border-right: solid 5px #000;
        box-sizing: border-box;
  background-size: 0%;
  background-repeat: no-repeat;
  animation: drawTheLine_2 1.5s ease-out 1 forwards;
  // animation-delay: 1.0s;
  }

  @keyframes drawTheLine_2 {
    0% {
      width: 0%;
    }
    50% {
      background: #000;
    }
    100% {
      background: #ffeb00;
      width: 90%;
      border-top: solid 5px;
      border-bottom: solid 5px;
      border-right: solid 5px;
      box-sizing: border-box;
    }
  }

.about_box {
    width: calc( 100% - 160px);
    text-align: left;
    display: flex;
    justify-content: space-between;
p.sub_name {
  margin: 80px;
    font-size: 50px;
    margin-bottom: 40px;
font-weight: 500;

}
p.sub_text {
  margin: 0 80px;
    font-family: "Hiragino Kaku Gothic ProN";
    line-height: 26px;
    text-align: left;
    letter-spacing: 1px;
}
.box_right {
  margin-top: 80px;
}
.photo {
  display: inline-block;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-image: url("../images/about_baseyard.jpg");
  background-position: center;
  border: solid 8px;
}
.photo_text {
      position: relative;
      top: -50%;
      left: 16%;
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      line-height: 45px;
}
}

}
// page_about


// page_products
.products {
  margin-top: 130px;

  .main_ttl{
        height: 70px;
        p.main_ttl_text {
          position: absolute;
          text-align: left;
          color: #fff;
          width: 90%;
          font-size: 50px;
          padding: 7px 80px 14px;
          box-sizing: border-box;
        }

        .background_line {
          position: absolute;
          background: #000;
          height: 70px;
          width: calc(100% - 160px);
        }
  }


// ホバーでグラデ
.snip1561.c_01 {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #0099d1;
  background: -moz-linear-gradient(90deg, #0099d1 0%, #4bb953 70%, #4bb953 70%);
  background: -webkit-linear-gradient(90deg, #0099d1 0%, #4bb953 70%, #4bb953 70%);
  background: linear-gradient(90deg, #0099d1 0%, #4bb953 70%, #4bb953 70%);
}
.snip1561.c_02 {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #f5cd31;
  background: -moz-linear-gradient(90deg, #f5cd31 0%, #0099d1 100%, #0099d1 100%);
  background: -webkit-linear-gradient(90deg, #f5cd31 0%, #0099d1 100%, #0099d1 100%);
  background: linear-gradient(90deg, #f5cd31 0%, #0099d1 100%, #0099d1 100%);
}
.snip1561.c_03 {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #4bb953;
  background: -moz-linear-gradient(90deg, #4bb953 0%, #f5cd31 100%, #f5cd31 100%);
  background: -webkit-linear-gradient(90deg, #4bb953 0%, #f5cd31 100%, #f5cd31 100%);
  background: linear-gradient(90deg, #4bb953 0%, #f5cd31 100%, #f5cd31 100%);
}
.snip1561.c_04 {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #d45657;
  background: -moz-linear-gradient(90deg, #d45657 0%, #A9A0D1 100%, #A9A0D1 100%);
  background: -webkit-linear-gradient(90deg, #d45657 0%, #A9A0D1 100%, #A9A0D1 100%);
  background: linear-gradient(90deg, #d45657 0%, #A9A0D1 100%, #A9A0D1 100%);
}
.snip1561.c_05 {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #3BD0AE;
  background: -moz-linear-gradient(90deg, #3BD0AE 0%, #a50e29 100%, #a50e29 100%);
  background: -webkit-linear-gradient(90deg, #3BD0AE 0%, #a50e29 100%, #a50e29 100%);
  background: linear-gradient(90deg, #3BD0AE 0%, #a50e29 100%, #a50e29 100%);
}
.snip1561.c_06 {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #c3e600;
  background: -moz-linear-gradient(90deg, #c3e600 0%, #0e113e 100%, #0e113e 100%);
  background: -webkit-linear-gradient(90deg, #c3e600 0%, #0e113e 100%, #0e113e 100%);
  background: linear-gradient(90deg, #c3e600 0%, #0e113e 100%, #0e113e 100%);
}
.snip1561.c_07 {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #9725EA;
  background: -moz-linear-gradient(90deg, #9725EA 0%, #f4b9e0 100%, #f4b9e0 100%);
  background: -webkit-linear-gradient(90deg, #9725EA 0%, #f4b9e0 100%, #f4b9e0 100%);
  background: linear-gradient(90deg, #9725EA 0%, #f4b9e0 100%, #f4b9e0 100%);
}

.snip1561 img,
.snip1561:before,
.snip1561:after {
  box-sizing: border-box;
  transition: all 0.4s ease;
}
.snip1561 img {
  max-width: 100%;
  backface-visibility: hidden;
  vertical-align: top;
}
// .snip1561:before,
// .snip1561:after {
//   content: '';
//   background-color: #fff;
//   position: absolute;
//   z-index: 1;
//   top: 50%;
//   left: 50%;
//   opacity: 0;
//   -webkit-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
// }
.snip1561:before {
  width: 60px;
  height: 5px;
  left: 100%;
}
.snip1561:after {
  height: 60px;
  width: 5px;
  top: 0%;
}
.snip1561 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
          cursor: url(../images/cursor.cur), auto;
}
.snip1561:hover img,
.snip1561.hover img {
  zoom: 1;
  filter: alpha(opacity=40);
  -webkit-opacity: 0.4;
  opacity: 0.4;
}
.snip1561:hover:before,
.snip1561.hover:before,
.snip1561:hover:after,
.snip1561.hover:after {
  opacity: 1;
  top: 50%;
  left: 50%;
}

  .item_all {
    position: relative;
    height: 2000px;
    .item {
      border: solid 8px;
      position: absolute;

      p {
        text-align: center;
        border-top: solid 8px #000;
        padding: 3px 0 0px;
        font-family: 'Teko';
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 1.3px;
        font-weight: 600;
      }

      p.buy_btn {
    position: absolute;
    border: solid 5px #000;
    right: 13px;
    top: -77px;
    width: 25px;
    height: 140px;
    padding: 5px 2px 5px 7px;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    background: #FFEB00;
    /* font-style: italic; */
    font-size: 26px;
    transform: rotate(-60deg);
        &:hover {
          color: red;
        }
      }

      .ttl_01 {
        color: #0099d1;
      }

      figure {
        position: relative;
    }

      .text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0.2;
        img {
          position: absolute;
          box-sizing: border-box;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    .item01 {
      width: 24%;
      top: 50px;
      left: 80px;
    }

    .item02 {
      width: 18%;
      top: 12%;
      left: 37%;
    }

    .item03 {
      width: 25%;
      top: 5%;
    right: 160px;
    }

    .item04 {
      width: 18%;
      top: 30%;
      left: 11%;
    }

    .item05 {
      width: 24%;
      top: 38%;
      right: 22%;
    }

    .item06 {
      width: 20%;
      top: 51%;
      left: 16%;
    }

    .item07 {
    width: 23%;
    top: 60%;
    right: 26%;
      .text img {
        width: 45%;
      }
      }
  }
}
// page_products


// page_shopInfo
.shopInfo {
      width: calc(100% - 160px);
        margin-top: 130px;

        .main_ttl {
          height: 70px;
        }

        p.main_ttl_text {
          position: absolute;
          text-align: left;
          color: #fff;
          width: 90%;
          font-size: 50px;
          padding: 7px 80px 14px;
          box-sizing: border-box;
        }

        .background_line {
          position: absolute;
          background: #000;
          height: 70px;
          width: calc(100% - 160px);
        }


.text_box {
  width: 100%;
  margin-top: 50px;
  font-family: "Hiragino Kaku Gothic ProN";
  display: flex;
  line-height: 30px;
  letter-spacing: 1px;
  margin-bottom: 200px;

  .box_left {
    width: 50%;
    padding-left: 80px;
    box-sizing: border-box;

          .ttl_baseyard {
            width: 275px;
            margin-top: 50px;
            margin-bottom: 20px;
            font-size: 32px;
            border-bottom: solid 3px;
            padding-bottom: 10px;
            font-weight: 500;
            }

            .flexbox {
              display: flex;
              align-items: flex-end;
              .box_left {
                    width: 70%;
                    padding: 0;
              }
              .box_right {
                    width: 30%;
            img {
              width: 30%;
              margin: 0 10%;
              &:hover {
                opacity: 0.6;
              }
            }
            }
          }

  }
  .box_right {
    width: 50%;
    text-align: right;
  }
}

.select_img {
  width: 100%;
    margin-top: 20px;
}

.select_img ul {
  width: 100%;
  display: flex;
li {
  display: flex;
  width: calc(100% / 3);
      font-weight: bold;
    box-sizing: border-box;
    padding: 5px 10px 0 20px;
    border-left: solid 5px #fff;
    border-top: solid 5px #fff;
    border-right: solid 5px #fff;

  &:hover {
    border-left: solid 5px #000;
    border-top: solid 5px #000;
    border-right: solid 5px #000;
    box-sizing: border-box;
    cursor: s-resize;
  }
}
  img.icon {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}

.default {
.change_text {
  width: 100%;
  background: #000;
  font-size: 18px;
  color: #fff;
    padding: 10px 15px;
    box-sizing: border-box;
}
}


/*タブ切り替え全体のスタイル*/
.tabs {
    margin-top: 20px;
    padding-bottom: 40px;
    background-color: #fff;
}

/*タブのスタイル*/
.tab_item {
  width: calc(100%/3);
  height: 40px;
  // border-bottom: 3px solid #000;
  background-color: #ffeb00;
  line-height: 40px;
  text-align: center;
  display: block;
  float: left;
  text-align: center;
  font-weight: bold;
  transition: all 0.2s ease;
  &:hover {
    color: red;
  }
}

.tab_item:hover {
  // opacity: 0.75;
  cursor: pointer;
}

/*ラジオボタンを全て消す*/
input[name="tab_item"] {
  display: none;
}

/*タブ切り替えの中身のスタイル*/
.tab_content {
  display: none;
  padding: 20px 0px 0;
  clear: both;
  overflow: hidden;
  line-height: 24px;
font-size: 16px;

}

/*選択されているタブのコンテンツのみを表示*/
#all:checked~#all_content,
#programming:checked~#programming_content,
#design:checked~#design_content {
  display: block;
}

/*選択されているタブのスタイルを変える*/
.tabs input:checked+.tab_item {
  background-color: #000;
  color: #fff;
}

.tabs input:checked+.tab_item::before {
  content: '';
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url(../images/icon_right2.png);
  background-size: contain;
  vertical-align: middle;
  margin-right: 10px;
}

}
// page_shopInfo


// page_item_共通
#main_area {

  .top_img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
  }

  .area01 {
    .box_main {
          font-family: "Hiragino Kaku Gothic ProN";
          font-size: 22px;
          line-height: 32px;
          text-align: left;
          letter-spacing: 2px;
    }
  }

  .area03 {
    width: calc(100% - 80px);
    height: 650px;
    background-size: cover;
    background-position: 50%;
    position: relative;
    margin-bottom: 200px;

    .text_box {
width: calc(100% - 160px);
display: flex;
color: #fff;
position: absolute;
margin: auto;
margin-top: 80px;
margin-bottom: 80px;
top: 0;
bottom: 0;
right: 0;
left: 0;
z-index: 1;
border: solid 8px;

     .box_ttl {
       width: 50%;
       position: relative;

       // スクロールでアクション
       .animation {
         position: absolute;
         top: 0;
         z-index: -1;
         width: 10%;
         height: 80%;
         bottom: 0;
         left: 10%;
         margin: auto;
         box-sizing: border-box;
         transition: all 1.3s ease-in;
         transition-delay: 0.4s;
       }

       .animation.active {
         width: 80%;
         opacity: 0.6;
       }

       p {
         font-size: 7vw;
         font-style: italic;
         position: absolute;
         top: 0;
         bottom: 0;
         margin: auto;
         height: 40%;
         left: 15%;
       }
     }

     .box_comment {
           width: 50%;
           padding: 50px;
           box-sizing: border-box;
           top: 0;
           bottom: 0;
           margin: auto;
           line-height: 32px;
           letter-spacing: 1px;
           text-shadow: 2px 2px 20px #000;
           font-size: 20px;
           font-weight: 500;
      }

      .box_center {
        background: #fff;
        height: 100%;
        width: 8px;
      }

    }
  }
  // area03

  // area05
  .area05 {
    p.co_ttl {
      font-size: 90px;
      font-style: italic;
    }
    p.co_border {
      width: 88%;
      height: 8px;
      background: #fff;
    }
    p.comment {
      line-height: 30px;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
  // area05
}

// page_item_共通


// page_item01
.item01 {

  .top_ttl {
    position: absolute;
    width: 83%;
    bottom: 160px;
    left: 80px;
  }

  .top_no {
    width: 130px;
    position: absolute;
    top: 160px;
    left: 80px;
  }

  .sq_border {
    width: 80px;
    height: 220px;
    background: #0099d1;
    position: absolute;
    left: 80px;
    bottom: 0;
    padding-top: 10px;
    box-sizing: border-box;

    p {
      color: #78b47c;
      font-size: 35px;
      text-align: center;
      padding: 3px 0px;
      box-sizing: border-box;
    }
  }

  .area01 {
    margin: 0px 0 0 80px;
    position: relative;

    .border {
      width: 80px;
      height: 200px;
      background: #0099d1;
      position: relative;
      left: 0px;
      top: 0;
      box-sizing: border-box;
    }

    .img01 {
      border: solid 15px #0099d1;
      border-left: solid 80px #0099d1;
      width: 65%;
    }

    .box_main {
      width: calc(100% - 160px);
      height: 500px;
      display: flex;
      border-left: solid 80px #0099d1;

      .box_left {
        width: 20%;
        position: relative;
        left: -125px;
        top: -300px;
      }

      .box_right {
        width: 80%;
        // text-align: right;
        padding-right: 10%;
        box-sizing: border-box;
        top: 50px;
        position: relative;
      }
    }

  }

  .area02 {
    margin-bottom: 150px;

    .photo_1 {
      width: calc(100% - 80px);
      position: relative;

      .background {
        width: 100%;
        height: 80%;
        background: #0099d1;
        position: absolute;
        top: 0;
        left: 0;
      }

      .ttl_img {
        width: 87%;
        position: relative;
        left: 80px;
        top: -80px;
      }

      #ttl_line {
        position: absolute;
        bottom: -15%;
        z-index: 1;
        width: 95%;
        margin: auto;
        left: 0;
        right: 0;

        path {
          fill: none;
          stroke: #0099d1;
          /*線の色を指定する*/
          stroke-dasharray: 2000;
          /*線の間隔を指定する*/
          stroke-dashoffset: 0;
          /*線の位置を指定する(IEは効かない属性)*/
          stroke-width: 5;
          /*線の太さを指定する*/
          -webkit-animation: hello 3s ease-in forwards;
          animation: hello 3s ease-in forwards;
        }

        @keyframes hello {
          0% {
            stroke-dashoffset: 2000;
            fill: #000;
            /*透過*/
          }

          50% {
            fill: transparent;
            /*透過*/
          }

          100% {
            stroke-dashoffset: 0;
            fill: transparent;
          }
        }
      }
    }

    .multiple {
      margin-top: 10%;

      div {
        margin: 0 5px;
      }
    }

    .photo_2 {
      position: relative;
      width: calc(100% - 80px);
      height: 600px;
      margin: 150px 0 15%;

      .background {
        position: absolute;
        background: #0099d1;
        width: 100%;
        height: 130px;
        margin: auto;
        top: 0;
        bottom: 0;
      }

      img {
        width: 70%;
        z-index: 10;
        position: absolute;
        right: 130px;
      }

    }

    .photo_3 {
      width: calc(100% - 80px);
      position: relative;

      .text_area {
        width: 100%;
        height: auto;
        position: absolute;
        margin: auto;
        top: -6%;

        .marquee {
          width: 100%;
          padding-left: 100%;
          white-space: nowrap;
          animation: marquee 4s linear infinite alternate;

          img {
            width: 190%;
          }
        }

        @keyframes marquee {
          0% {
            margin-left: 100%;
            transform: translateX(-40%)
          }

          100% {
            margin-left: -100%;
            transform: translateX(-100%)
          }
        }
      }

      .img_area {
        width: 65%;
        background: #0099d1;
        box-sizing: border-box;
        margin: auto;

        img {
          padding: 20%;
          box-sizing: border-box;

        }
      }
    }

  }

  // area02

  .area03 {
    background-image: url("../images/item01/img_10.jpg");

    .text_box {
      .box_ttl {
        .animation {
          background: #0099d1;
        }
      }
    }
  }

  .area03::after {
    content: '';
    background-color: rgba(0, 153, 209, .3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .area04 {
    margin-bottom: 200px;

    .detail {
      width: calc(100% - 80px);
      position: relative;

      .border {
        width: 100%;
        height: 80px;
        background: #0099d1;
        position: absolute;
        left: 0;
        bottom: 35px;
      }

      img {
        height: 235px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
      }
    }


    .flexbox {
      width: calc(100% - 80px);
      margin-top: 80px;
      box-sizing: border-box;
      display: flex;

      .box_left {
        width: 50%;

        img {
          border: solid 15px #0099d1;
          width: 90%;
          margin-left: 80px;
          box-sizing: border-box;
        }
      }

      .box_right {
        width: 50%;
        text-align: center;
        padding-top: 20%;
        box-sizing: border-box;

      }
    }

  }

  .area05 {
    width: calc(100% - 80px);
    height: 450px;
    background-size: cover;
    // background-image: url("../images/item03/img_11.jpg");
    background-position: 50%;
    background: #000;
    position: relative;
    margin-bottom: 150px;
    color: #fff;

    p.comment {
      width: 95%;
      position: absolute;
      left: 40px;
      top: 160px;
      padding: 50px;
      box-sizing: border-box;
    }

    p.co_ttl {
      position: absolute;
      top: 45px;
      right: 80px;
    }

    p.co_border {
      top: 150px;
      position: absolute;
      left: 80px;
    }
  }


  .area06 {
    width: calc(100% - 80px);
    margin-bottom: 300px;

    .flexbox {
      display: flex;

      .box_left {
        width: 50%;
        text-align: center;

        .photo {
          display: inline-block;
          width: 350px;
          height: 350px;
          border-radius: 350px;
          background: #000;
          background-position: center;
          background-size: contain;
          border: solid 8px #000;
          color: #fff;
          padding-top: 150px;
          box-sizing: border-box;
          font-size: 33px;
          letter-spacing: 2px;
          color: #ffeb00;
        }
      }

      .box_right {
        width: 55%;
        text-align: left;
        padding-top: 7%;
        padding-right: 80px;
        box-sizing: border-box;
        line-height: 30px;
      }
    }
  }

}

//page_item01


// page_item02
.item02 {

.top_ttl {
  position: absolute;
  width: 63%;
  top: 160px;
  left: 80px;
}
  .top_no {
    width: 130px;
    position: absolute;
    bottom: 160px;
    right: 160px;
  }

  .sq_border {
    width: 80px;
    height: 220px;
    background: #f5cd31;
    position: absolute;
    left: 80px;
    bottom: 0;
    padding-top: 10px;
    box-sizing: border-box;

    p {
    color: #56c3ea;
      font-size: 35px;
      text-align: center;
      padding: 3px 0px;
      box-sizing: border-box;
    }
  }

.area01 {
  margin: 0px 0 0 80px;
  position: relative;

  .border {
    width: 80px;
    height: 200px;
    background: #f5cd31;
    position: relative;
    left: 0px;
    top: 0;
    box-sizing: border-box;
  }

  .img01 {
    border: solid 15px #f5cd31;
    border-left: solid 80px #f5cd31;
    width: 50%;
  }

  .box_main {
    width: calc(100% - 160px);
    height: 350px;
    display: flex;
    border-left: solid 80px #f5cd31;

    .box_left {
    width: 20%;
    position: relative;
    left: -125px;
    top: -400px;
    }

    .box_right {
    width: 80%;
    padding-right: 4%;
    padding-left: 42%;
    font-size: 22px;
    font-family: "Hiragino Kaku Gothic ProN";
    box-sizing: border-box;
    top: -250px;
    position: relative;
    line-height: 32px;
    text-align: left;
    letter-spacing: 2px;
    }
    }

    #ttl_line {
      position: absolute;
      bottom: -5%;
      left: 160px;
      z-index: 1;
      width: 77%;

      path {
        fill: none;
        stroke: #f5cd31;
        /*線の色を指定する*/
        stroke-dasharray: 2000;
        /*線の間隔を指定する*/
        stroke-dashoffset: 0;
        /*線の位置を指定する(IEは効かない属性)*/
        stroke-width: 5;
        /*線の太さを指定する*/
        -webkit-animation: hello 3s ease-in forwards;
        animation: hello 3s ease-in forwards;
      }

      @keyframes hello {
        0% {
          stroke-dashoffset: 2000;
          fill: #000;
          /*透過*/
        }

        50% {
          fill: transparent;
          /*透過*/
        }

        100% {
          stroke-dashoffset: 0;
          fill: transparent;
        }
      }
    }
}

.area02 {
  margin-bottom: 150px;
  .photo_1 {
        position: relative;
    .background {
    width: 75%;
    background: #f5cd31;
    position: absolute;
    top: 20%;
    left: 10%;
    height: 95%;
    }
  img {
    width: 78%;
    position: relative;
  }
  }

.multiple {
 margin-top: 13%;
  div {
  margin: 0 5px;
}
}

.photo_2 {
  position: relative;
  img {
    width: 40%;
    margin: 150px auto;
        z-index: 10;
        position: relative;
  }

  .text_area {
    width: 100%;
    height: 30%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    .marquee {
      width: 100%;
      padding-left: 100%;
      white-space: nowrap;
      animation: marquee 4s linear infinite alternate;
    }

    @keyframes marquee {
      0% {
        margin-left: 100%;
        transform: translateX(-50%)
      }

      100% {
        margin-left: -100%;
        transform: translateX(-50%)
      }
    }
  }
}

 .photo_3 {
   width: 60%;
   margin: 0 auto;
   background: #f5cd31;

   img {
     padding: 150px;
     box-sizing: border-box;
   }
 }

}
// area02


.area03 {
  background-image: url("../images/item02/img_12.jpg");

  .text_box {
    .box_ttl {
      .animation {
        background: #f5cd31;
      }
    }
  }
}
.area03::after {
  content: '';
  background-color: rgba(245, 205, 49, .3);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}


.area04 {
  .detail {
    width: calc(100% - 80px);
    position: relative;

    .border {
      width: 100%;
      height: 80px;
      background: #f5cd31;
      position: absolute;
      left: 0;
      bottom: 35px;
    }

    img {
      height: 235px;
      margin: 0 auto;
      z-index: 10;
      position: relative;
    }
  }


  .select_img {
    width: calc(100% - 80px);
    padding: 80px 80px 20px;
    box-sizing: border-box;
    display: flex;
  }

  .select_img p {
    width: 75%;
    height: 75%;
    margin: 0 10px;
    border: solid 15px #f5cd31;
    box-sizing: border-box;
  }

  .select_img ul {
    width: 50%;
    margin: auto;
    display: block;
    overflow: hidden;
    clear: both;
    box-sizing: border-box;
  }

  .select_img li {
    width: calc(100% / 2);
    display: block;
    float: left;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
  }
  .select_img li.right {
    float: right;
  }

  .select_img li img {
    cursor: pointer;
  }

  .select_img li img:hover {
    border: solid 8px #0099d1;
    box-sizing: border-box;
  }

  .change_text {
    width: calc(100% - 80px);
    text-align: center;
    margin-bottom: 200px;
  }
}


.area05 {
  width: calc(100% - 80px);
  height: 670px;
  background-size: cover;
  background-image: url("../images/item02/img_13.jpg");
  background-position: 50%;
  position: relative;
  margin-bottom: 250px;
  color: #fff;

  p.comment {
    width: 600px;
    position: absolute;
    right: 80px;
    top: 200px;
    padding: 30px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.3);
  }

  p.co_ttl {
    position: absolute;
    top: 45px;
    left: 80px;
    padding: 0 0 8px;
    width: 89%;
  }
      p.co_border {
        top: 140px;
        position: absolute;
        left: 80px;
      }
}

.cheer_box .box_btn {
  width: 45%;
 }
}//page_item02


// page_item03
.item03 {

  .top_ttl {
    position: absolute;
    width: 63%;
    bottom: 140px;
    right: 160px;
  }

  .top_no {
    width: 130px;
    position: absolute;
    top: 160px;
    right: 160px;
  }

  .sq_border {
    width: 80px;
    height: 220px;
    background: #4bb953;
    position: absolute;
    left: 80px;
    bottom: 0;
    padding-top: 10px;
    box-sizing: border-box;

    p {
      color: #f5cd31;
      font-size: 35px;
      text-align: center;
      padding: 3px 0px;
      box-sizing: border-box;
    }
  }

  .area01 {
    margin: 0px 0 0 80px;

    .border {
      width: 80px;
      height: 200px;
      background: #4bb953;
      position: relative;
      left: 0px;
      top: 0;
      box-sizing: border-box;
    }

    .img01 {
      border: solid 15px #4bb953;
      border-left: solid 80px #4bb953;
      width: 65%;

    }

    .box_main {
      width: calc(100% - 160px);
      height: 650px;
      display: flex;
      border-left: solid 80px #4bb953;

      .box_left {
        width: 20%;
        position: relative;
        left: -125px;
        top: -125px;
      }

      .box_right {
        width: 70%;
        margin-top: 5%;
        z-index: 10;

        #ttl_line {
          width: 100%;
          padding-left: 10%;
          box-sizing: border-box;
          margin-top: 5%;

          path {
            fill: none;
            stroke: #4bb953;
            /*線の色を指定する*/
            stroke-dasharray: 2000;
            /*線の間隔を指定する*/
            stroke-dashoffset: 0;
            /*線の位置を指定する(IEは効かない属性)*/
            stroke-width: 5;
            /*線の太さを指定する*/
            -webkit-animation: hello 3s ease-in forwards;
            animation: hello 3s ease-in forwards;
          }

          @keyframes hello {
            0% {
              stroke-dashoffset: 2000;
              fill: #000;
              /*透過*/
            }

            50% {
              fill: transparent;
              /*透過*/
            }

            100% {
              stroke-dashoffset: 0;
              fill: transparent;
            }
          }
        }

      }
    }
  }

  .area02 {
    .photo_1 {
      background: #777;
      width: calc(100% - 80px);
      height: 600px;
      transform: skewY(-8deg);
      overflow: hidden;
      position: relative;
      top: -120px;

      img {
        width: 100%;
        transform: skewY(8deg) scale(1.23);
      }
    }

    .movie_1 {
      width: calc(100% - 80px);
      text-align: right;
      background: linear-gradient(0deg, #4bb953 0%, #4bb953 150px, #fff 150px, #fff 100%);
      margin-bottom: 150px;
      margin-top: 50px;

      video {
        width: 60%;
        border: solid 15px;
        margin-right: 80px;
        margin-bottom: 65px;
      }
    }

    .photo_2 {
      background: linear-gradient(0deg, #4bb953 0%, #4bb953 150px, #fff 150px, #fff 100%);
      margin-bottom: 200px;

      img {
        width: 60%;
        padding-left: 80px;
        padding-bottom: 70px;
        box-sizing: border-box;
      }
    }
  }

  .area03 {
    background-image: url("../images/item03/img_3.jpg");

    .text_box {
      .box_ttl {
        .animation {
          background: #4bb953;
        }
      }
    }
  }

  .area03::after {
    content: '';
    background-color: rgba(75, 185, 83, .3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .area04 {
    .detail {
      width: calc(100% - 80px);
      position: relative;

      .border {
        width: 100%;
        height: 80px;
        background: #4bb953;
        position: absolute;
        left: 0;
        bottom: 35px;
      }

      img {
        height: 235px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
      }
    }


    .select_img {
      width: calc(100% - 80px);
      padding: 80px 80px 10px;
      box-sizing: border-box;
      display: flex;
    }

    .select_img p {
      width: 50%;
    margin: 10px 10px 10px 0;
      border: solid 15px #4bb953;
      box-sizing: border-box;
    }

    .select_img ul {
      width: 50%;
      margin: auto;
      display: block;
      overflow: hidden;
      clear: both;
      padding: 5px;
      box-sizing: border-box;

    }

    .select_img li {
      width: calc(100% / 3);
      display: block;
      float: left;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
    }

    .select_img li img {
      cursor: pointer;
    }

    .select_img li img:hover {
      border: solid 8px #f5cd31;
      box-sizing: border-box;
    }

    .change_text {
      margin-left: 95px;
      width: 40%;
      margin-bottom: 200px;
      line-height: 26px;
    }
  }

  .area05 {
    width: calc(100% - 80px);
    height: 750px;
    background-size: cover;
    background-image: url("../images/item03/img_4.jpg");
    background-position: 50%;
    position: relative;
    margin-bottom: 250px;
    color: #fff;

    p.comment {
      width: 500px;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: 30px;
      top: 40px;
      padding: 30px;
      box-sizing: border-box;
    }

    p.co_ttl {
      position: absolute;
      bottom: 60px;
      left: 80px;
    }

    p.co_border {
      left: 80px;
      position: absolute;
      bottom: 50px;
    }
  }


  .area06 {
    margin-bottom: 250px;

    .other_p {
      width: calc(100% - 80px);
      position: relative;

      .border {
        width: 100%;
        height: 80px;
        background: #4bb953;
        position: absolute;
        left: 0;
        bottom: 35px;
      }

      img {
        height: 235px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
      }
    }

    figure {
      position: relative;
      overflow: hidden;
      width: 40%;
      margin: auto;
      padding-right: 80px;
      box-sizing: border-box;
      margin-top: 80px;

      img {
        border: solid 15px #4bb953;
        box-sizing: border-box;
      }
    }

    figcaption {
      position: absolute;
      padding-right: 80px;
      box-sizing: border-box;
      bottom: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      -webkit-transition: .5s;
      transition: .5s;

      p {
        color: rgba(255, 255, 255, 0.7);
        font-size: 120px;
        font-family: "Teko";
        text-align: center;
        margin-top: 37%;
      }
    }

    figure:hover figcaption {
      bottom: 100%;
    }
  }
}

//page_item03


// page_item04
.item04 {

  .top_ttl {
    position: absolute;
    width: 63%;
    bottom: 0;
    top: 0;
    left: 80px;
    margin: auto;
  }

  .top_no {
    width: 130px;
    position: absolute;
    top: 160px;
    right: 160px;
  }

  .sq_border {
    width: 80px;
    height: 220px;
    background: #e84252;
    position: absolute;
    left: 80px;
    bottom: 0;
    padding-top: 10px;
    box-sizing: border-box;
    p {
      color: #e8cbed;
      font-size: 35px;
      text-align: center;
      padding: 3px 0px;
      box-sizing: border-box;
    }
  }

  .area01 {
    margin: 0px 0 0 80px;

    .border {
      width: 80px;
      height: 200px;
      background: #e84252;
      position: relative;
      left: 0px;
      top: 0;
      box-sizing: border-box;
    }

    .img01 {
      border: solid 15px #e84252;
      border-left: solid 80px #e84252;
      width: 70%;

    }

    .box_main {
      width: calc(100% - 160px);
          height: 450px;
      display: flex;
      border-left: solid 80px #e84252;
      .box_left {
        width: 20%;
        position: relative;
        left: -125px;
        top: -390px;
      }
      .box_right {
        width: 70%;
        margin-top: 50px;
        font-size: 22px;
        font-family: "Hiragino Kaku Gothic ProN";
      }
    }
  }

  .area02 {
    position: relative;
    .flexbox {
      width: calc(100% - 160px);
      position: relative;
      left: 80px;
      display: flex;
      z-index: 10;
      .box_left {
        width: 45%;
      }
      .box_right {
        width: 55%;
        img {
    position: relative;
    left: -7%;
    top: 15%;
        }

#ttl_line {
  width: 90%;

  padding-left: 10%;
  box-sizing: border-box;
  margin-top: 22%;

  path {
    fill: none;
    stroke: #e84252;
    /*線の色を指定する*/
    stroke-dasharray: 2000;
    /*線の間隔を指定する*/
    stroke-dashoffset: 0;
    /*線の位置を指定する(IEは効かない属性)*/
    stroke-width: 5;
    /*線の太さを指定する*/
    -webkit-animation: hello 3s ease-in forwards;
    animation: hello 3s ease-in forwards;
  }

  @keyframes hello {
    0% {
      stroke-dashoffset: 2000;
      fill: #000;
      /*透過*/
    }

    50% {
      fill: transparent;
      /*透過*/
    }

    100% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
  }
}
      }
    }

    .background {
    background: #e84252;
    width: 60%;
    height: 15%;
    position: absolute;
    left: 15%;
    top: 300px;
    }

    .movie_1 {
      position: relative;
      width: calc(100% - 80px);
      text-align: right;
      margin: 20% 0 50px;

      video {
        width: 60%;
        border: solid 15px;
        margin-right: 80px;
        margin-bottom: 65px;
            z-index: 10;
            position: relative;
      }
          .background {
            background: #e84252;
            width: 100%;
            height: 10%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
    }

    .photo_2 {
      position: relative;
    height: 800px;
    margin-bottom: 200px;

      .background {
      background: #e84252;
      width: 60%;
      height: 300px;
      position: absolute;
      left: 10%;
      top: 35%;
    }
      .photo_A {
        position: absolute;
        width: 50%;
        left: 80px;
      }
        .photo_B {
          position: absolute;
          width: 25%;
          right: 160px;
          bottom: 0;
        }
    }
  }

  .area03 {
    background-image: url("../images/item04/img_5.jpg");

    .text_box {
      .box_ttl {
        .animation {
          background: #e84252;
        }
      }
    }
  }
  .area03::after {
    content: '';
    background-color: rgba(232, 66, 82, .3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .area04 {
    .detail {
      width: calc(100% - 80px);
      position: relative;

      .border {
        width: 100%;
        height: 80px;
        background: #e84252;
        position: absolute;
        left: 0;
        bottom: 35px;
      }

      img {
        height: 235px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
      }
    }

    .select_img {
      width: calc(100% - 80px);
      padding: 80px 80px 200px;
      box-sizing: border-box;
      display: flex;
    }

    .select_img p {
      width: 50%;
      margin: 10px;
      border: solid 15px #e84252;
      box-sizing: border-box;
    }

    .select_img ul {
      width: 50%;
      margin: auto;
      display: block;
      overflow: hidden;
      clear: both;
      padding-left: 20px;
      box-sizing: border-box;
      text-align: center;
      line-height: 26px;
      letter-spacing: 1px;
    }

    .select_img li {
      width: calc(100% / 3);
      display: block;
      float: left;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
    }

    .select_img li img {
      cursor: pointer;
    }

    .select_img li img:hover {
      border: solid 8px #f5cd31;
      border-radius: 50%;
      box-sizing: border-box;
    }

    .change_text {
      text-align: center;
    }
  }

  .area05 {
    width: calc(100% - 80px);
    height: 600px;
    background-size: cover;
    background-image: url("../images/item04/img_6.jpg");
    background-position: 50%;
    position: relative;
    margin-bottom: 250px;
    color: #fff;

    p.comment {
      width: 500px;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: 60px;
      top: 80px;
      padding: 50px;
      box-sizing: border-box;
    }

    p.co_ttl {
      position: absolute;
      bottom: 60px;
      left: 80px;
    }

    p.co_border {
      left: 80px;
      position: absolute;
      bottom: 50px;
    }
  }


  .area06 {
    margin-bottom: 250px;

    .other_p {
      width: calc(100% - 80px);
      position: relative;

      .border {
        width: 100%;
        height: 80px;
        background: #e84252;
        position: absolute;
        left: 0;
        bottom: 35px;
      }

      img {
        height: 230px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
      }
    }

    figure {
      position: relative;
      overflow: hidden;
      width: 50%;
      margin: auto;
      padding-right: 80px;
      box-sizing: border-box;
      margin-top: 80px;

      img {
        border: solid 15px #e84252;
        box-sizing: border-box;
      }
    }

    figcaption {
      position: absolute;
      padding-right: 80px;
      box-sizing: border-box;
      bottom: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      -webkit-transition: .5s;
      transition: .5s;

      p {
        color: rgba(255, 255, 255, 0.7);
        font-size: 120px;
        font-family: "Teko";
        text-align: center;
        margin-top: 33%;
      }
    }

    figure:hover figcaption {
      bottom: 100%;
    }
  }
}
//page_item04


// page_item05
.item05 {

.top_ttl {
    position: absolute;
    width: 58%;
    bottom: 15%;
    left: 15%;
    margin: auto;
}

  .top_no {
    width: 130px;
    position: absolute;
    bottom: 15%;
    right: 160px;
  }

  .sq_border {
    width: 80px;
    height: 220px;
    background: #3BD0AE;
    position: absolute;
    left: 80px;
    bottom: 0;
    padding-top: 10px;
    box-sizing: border-box;

    p {
      color: #a50e29;
      font-size: 35px;
      text-align: center;
      padding: 3px 0px;
      box-sizing: border-box;
    }
  }

  .area01 {
    margin: 0px 0 0 80px;

    .border {
      width: 80px;
      height: 200px;
      background:#3BD0AE;
      position: relative;
      left: 0px;
      top: 0;
      box-sizing: border-box;
    }

    .img01 {
      border: solid 15px#3BD0AE;
      border-left: solid 80px#3BD0AE;
      width: 55%;
    }

    .box_main {
      width: calc(100% - 160px);
      height: 580px;
      display: flex;
      border-left: solid 80px#3BD0AE;
      .box_left {
        width: 20%;
        position: relative;
        left: -125px;
        top: -460px;
      }

      .box_right {
    width: 80%;
    margin-top: 5%;
    z-index: 10;
    padding: 0 10% 0 0;
    box-sizing: border-box;
      }
    }
  }


.area02 {
    position: relative;
.ttl_line {
    width: 100%;
    position: absolute;
    top: -10%;
    left: 20%;
}

#ttl_line {
  width: 70%;
  box-sizing: border-box;

  path {
    fill: none;
    stroke: #3BD0AE;
    /*線の色を指定する*/
    stroke-dasharray: 2000;
    /*線の間隔を指定する*/
    stroke-dashoffset: 0;
    /*線の位置を指定する(IEは効かない属性)*/
    stroke-width: 5;
    /*線の太さを指定する*/
    -webkit-animation: hello 3s ease-in forwards;
    animation: hello 3s ease-in forwards;
  }

  @keyframes hello {
    0% {
      stroke-dashoffset: 2000;
      fill: #000;
      /*透過*/
    }

    50% {
      fill: transparent;
      /*透過*/
    }

    100% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
  }
}

    .photo_1 {
      position: relative;
      margin-bottom: 100px;
      img {
        position: relative;
        width: 55%;
        padding-left: 80px;
        padding-bottom: 70px;
        box-sizing: border-box;
            z-index: 10;
      }
      .background {
            position: absolute;
            background: #3BD0AE;
            width: 100%;
            height: 80px;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 80px;
      }
    }

    .movie_1 {
      position: relative;
      width: calc(100% - 160px);

      img {
        border: solid 15px #000;
      width: 40%;
      position: relative;
      z-index: 10;
      margin-left: auto;
      }
    }

    .photo_2 {
      position: relative;
      margin-bottom: 20%;
      padding-bottom: 5%;
      top: -200px;
      border: solid 80px #3BD0AE;
      width: calc(100% - 400px);
      box-sizing: border-box;
      margin-left: 160px;

      img {
    width: 50%;
    position: absolute;
      }

      .img_a{
        position: relative;
    left: -80px;
    top: 80px;
      }

      .img_b {
        right: -80px;
        margin-left: auto;
        }

        .movie_2 {
          position: relative;
          width: 65%;
          left: -160px;
    margin: 20% 0;
        }

        .img_c {
          width: 70%;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -30%;
        }
    }

  }


  .area03 {
    background-image: url("../images/item05/img_5.jpg");

    .text_box {
      .box_ttl {
        .animation {
          background:#3BD0AE;
        }
      }
    }
  }

  .area03::after {
    content: '';
    background-color: rgba(59, 208, 174, .2);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .area04 {
    .detail {
      width: calc(100% - 80px);
      position: relative;

      .border {
        width: 100%;
        height: 80px;
        background:#3BD0AE;
        position: absolute;
        left: 0;
        bottom: 35px;
      }

      img {
        height: 235px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
      }
    }

    .select_img {
      width: calc(100% - 80px);
      padding: 80px 80px 0px;
      box-sizing: border-box;
      display: flex;
      position: relative;
      margin-bottom: 200px;
    }

    .select_img p {
    width: 50%;
    margin: 0 10px 0 0;
    border: solid 15px #3BD0AE;
    box-sizing: border-box;
    }

    .select_img ul {
      width: 50%;
      margin: auto;
      display: block;
      overflow: hidden;
      clear: both;
      padding: 5px;
      box-sizing: border-box;

    }

    .select_img li {
      width: calc(100% / 3);
      display: block;
      float: left;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
    }

    .select_img li img {
      cursor: pointer;
    }

    .select_img li img:hover {
      border: solid 8px #a50e29;
      box-sizing: border-box;
    }

    .change_text {
    width: 50%;
    line-height: 26px;
    box-sizing: border-box;
    position: absolute;
    margin: auto;
    right: 0;
    top: 70%;
    padding: 0 80px 0 30px;
    }

  }

  .area05 {
    width: calc(100% - 80px);
    height: 750px;
    background-size: cover;
    background-image: url("../images/item05/img_6.jpg");
    background-position: 50%;
    position: relative;
    margin-bottom: 250px;
    color: #fff;

    p.comment {
      width: 500px;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: 30px;
      top: 40px;
      padding: 30px;
      box-sizing: border-box;
    }

    p.co_ttl {
      position: absolute;
      bottom: 60px;
      left: 80px;
    }

    p.co_border {
      left: 80px;
      position: absolute;
      bottom: 50px;
    }
  }

}
//page_item05


// page_item06
.item06 {

  .top_ttl {
    position: absolute;
    width: 70%;
    bottom: 0;
    top: 0;
    right: 40px;
    left: 0px;
    margin: auto;
  }

  .top_no {
    width: 130px;
    position: absolute;
    top: 15%;
    left: 80px;
  }

  .sq_border {
    width: 80px;
    height: 220px;
    background: #c3e600;
    position: absolute;
    left: 80px;
    bottom: 0;
    padding-top: 10px;
    box-sizing: border-box;

    p {
      color: #0e113e;
      font-size: 35px;
      text-align: center;
      padding: 3px 0px;
      box-sizing: border-box;
    }
  }

  .area01 {
    margin: 0px 0 0 80px;

    .border {
      width: 80px;
      height: 200px;
      background: #c3e600;
      position: relative;
      left: 0px;
      top: 0;
      box-sizing: border-box;
    }

    .img01 {
      border: solid 15px#c3e600;
      border-left: solid 80px#c3e600;
      width: 50%;
    }

    .box_main {
      width: calc(100% - 160px);
      height: 400px;
      display: flex;
      border-left: solid 80px#c3e600;
      position: relative;

      .box_left {
        width: 20%;
        position: relative;
        z-index: 1;
        left: -125px;
        bottom: 100%;
      }

      .box_right {
        width: 32%;
        z-index: 10;
        box-sizing: border-box;
        position: absolute;
        bottom: 60%;
        right: 5%;
      }
    }

  }


  .area02 {
    position: relative;
    margin-bottom: 200px;

.photo_1 {
  position: relative;

  img {
    position: relative;
    width: calc(100% - 240px);
    padding-left: 80px;
    box-sizing: border-box;
    z-index: 10;
  }

  .background {
    position: absolute;
    background: #c3e600;
    width: calc(100% - 240px);
    height: 100%;
    margin: auto;
    top: -80px;
    left: 80px;
  }
}

.ttl_line {
    width: 100%;
    position: relative;
    left: 100px;
    z-index: 100;
    top: -50px;
    }

    #ttl_line {
      width: 80%;
      box-sizing: border-box;
      position: absolute;
      path {
        @include line-anime;
        stroke: #c3e600;
      }
    }

video {
  width: 50%;
  border: solid 15px;
  margin: 150px auto 100px;
  display: block;
}

.slide_img {
   position: relative;
 .multiple div {
  margin: 0 15px;
}
.background {
      position: absolute;
      background: #c3e600;
      width: 100%;
      height: 80px;
      margin: auto;
      top: 0;
      bottom: 0;
}
}

.photo_2 {
    position: relative;
  background: #c3e600;
  width: 50%;
  margin: 100px 0;
padding: 5% 0;

img.photo {
    left: 50%;
    position: relative;
    margin: 20% 0;padding: 5% 0;
}
img.edo {
  width: 20%;
  position: absolute;
  top: 5%;
  left: 15%;
}
img.edo_w {
  width: 20%;
  position: absolute;
  top: 6%;
  left: 16%;
}
}

.photo_3 {
  position: relative;
  width: calc(100% - 80px);
  margin-bottom: 200px;
  padding-top: 3%;

    // スクロールでアクション
    .animation_2 {
    color: #c3e600;
    position: absolute;
    bottom: 30px;
    font-size: 5.5rem;
    transition: all 0.5s ease-out;
    transition-delay: 1.5s;
    opacity: 0;
    text-shadow: 5px 6px #fff;
    z-index: 10;
    font-weight: 500;
    left: 40px;
    width: 50%;
    }

    .animation_2.active {
    bottom: 100px;
    opacity: 1;
  }

    .background {
          position: absolute;
          background: #c3e600;
          height: 60px;
          bottom: 60px;
          width: 100%;
        }
        img {
          position: relative;
          width: 40%;
          margin-left: auto;
          margin-right: 80px;
        }
      }


  }


  .area03 {
    background-image: url("../images/item06/img_5.jpg");

    .text_box {
      .box_ttl {
        .animation {
          background: #c3e600;
        }
      }
    }
  }

  .area03::after {
    content: '';
    background-color: rgba(195, 230, 0, .3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .area04 {
    .detail {
      width: calc(100% - 80px);
      position: relative;

      .border {
        width: 100%;
        height: 80px;
        background: #c3e600;
        position: absolute;
        left: 0;
        bottom: 35px;
      }

      img {
        height: 235px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
      }
    }

    .select_img {
      width: calc(100% - 80px);
      padding: 80px 80px 0px;
      box-sizing: border-box;
      display: flex;
      position: relative;
      margin-bottom: 200px;
    }

    .select_img p {
      width: 50%;
      margin: 0 10px 0 0;
      border: solid 15px #c3e600;
      box-sizing: border-box;
    }

    .select_img ul {
      width: 50%;
      margin: auto;
      display: block;
      overflow: hidden;
      clear: both;
      padding: 5px;
      box-sizing: border-box;

    }

    .select_img li {
      width: calc(100% / 3);
      display: block;
      float: left;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
    }

    .select_img li img {
      cursor: pointer;
    }

    .select_img li img:hover {
      border: solid 8px #0e113e;
      box-sizing: border-box;
    }

    .change_text {
      width: 50%;
      line-height: 26px;
      box-sizing: border-box;
      position: absolute;
      margin: auto;
      right: 0;
      top: 70%;
      padding: 10px 80px 0 20px;
    }

  }

  .area05 {
    width: calc(100% - 80px);
    height: 500px;
    background-size: cover;
    background-image: url(../images/item06/img_6.jpg);
    position: relative;
    margin-bottom: 100px;
    color: #fff;

    p.comment {
      width: 400px;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 30px;
      top: 40px;
      padding: 30px;
      box-sizing: border-box;
    }

    p.co_ttl {
      position: absolute;
      bottom: 60px;
      right: 80px;
    }

    p.co_border {
      left: 80px;
      position: absolute;
      bottom: 50px;
    }
  }

   .area05_2 {
     width: calc(100% - 80px);
     height: 500px;
     background-size: cover;
     background-image: url(../images/item06/img_7.jpg);
     position: relative;
     margin-bottom: 250px;
     color: #fff;
   }

}
//page_item06


// page_item07
.item07 {

  .top_ttl {
    position: absolute;
    width: 70%;
    bottom: 120px;
    right: 120px;
    margin: auto;
  }

  .top_no {
    width: 130px;
    position: absolute;
    top: 120px;
    left: 80px;
  }

  .sq_border {
    width: 80px;
    height: 220px;
    background: #9725EA;
    position: absolute;
    left: 80px;
    bottom: 0;
    padding-top: 10px;
    box-sizing: border-box;

    p {
      color: #f4b9e0;
      font-size: 35px;
      text-align: center;
      padding: 3px 0px;
      box-sizing: border-box;
    }
  }

  .area01 {
    margin: 0px 0 0 80px;

    .border {
      width: 80px;
      height: 200px;
      background: #9725EA;
      position: relative;
      left: 0px;
      top: 0;
      box-sizing: border-box;
    }

    .img01 {
      border: solid 15px#9725EA;
      border-left: solid 80px#9725EA;
      width: 50%;
    }

    .box_main {
      width: calc(100% - 160px);
      height: 500px;
      display: flex;
      border-left: solid 80px#9725EA;
      position: relative;

      .box_left {
        width: 20%;
        position: relative;
        z-index: 1;
        left: -125px;
        bottom: 30%;
      }

      .box_right {
        width: 80%;
        margin-top: 5%;
        z-index: 10;
        padding: 0 10% 0 0;
        box-sizing: border-box;
      }
    }

  }


  .area02 {
    position: relative;
    margin-bottom: 200px;

    .photo_1 {
      position: relative;
      .border {
        position: absolute;
        background: #9725EA;
        width: 80px;
        height: 50%;
        left: 80px;
      }

      img {
        position: relative;
        width: 60%;
        margin-left: auto;
        box-sizing: border-box;
        z-index: 10;
        padding-right: 160px;
      }

      .animation_border {
        position: absolute;
        background: #9725EA;
        width: 0px;
        height: 80px;
        margin: auto;
        top: 0;
        left: 80px;
        bottom: 0;
        transition: all 1s ease-out;
        transition-delay: .5s;
      }

      .animation_border.active {
        width: calc(100% - 80px);
      }
    }

    .ttl_line {
      width: 100%;
      position: relative;
      left: 100px;
      z-index: 100;
      top: -50px;
    }
    #ttl_line {
      width: 60%;
      box-sizing: border-box;
      position: absolute;

      path {
        @include line-anime;
        stroke: #9725EA;
      }
    }

    .photo_3 {
      position: relative;
      margin-top: 220px;

      img {
        position: relative;
        width: 40%;
        box-sizing: border-box;
        z-index: 10;
        padding-left: 80px;
      }

      .animation_border {
        position: absolute;
        background: #9725EA;
        width: 0px;
        height: 80px;
        margin: auto;
        top: 0;
        left: 0px;
        bottom: 0;
        transition: all 1s ease-out;
        transition-delay: .5s;
      }

      .animation_border.active {
        width: calc(100% - 80px);
      }

      /* clock */
      div#clock_frame {
        position: absolute;
        width: calc(100% - 90px);
        height: 70%;
        font-family: 'Press Start 2P', sans-serif;
        font-weight: bold;
        text-align: right;
        color: #9725EA;
        top: 0;
        bottom: 0;
        margin: auto;

        span {
          display: block;
          // float: right;
        }
        span#clock_date {
          font-size: 1.5rem;
          line-height: 3rem;
        }
        span#clock_time {
          font-size: 3.5rem;
        }
      }
    }



    .photo_2 {
      position: relative;
      width: calc(100% - 240px);
      margin: 100px 0 150px 80px;
      img {
        z-index: 10;
      }

      img.photo_A {
      position: relative;
        width: 50%;
        margin-left: auto;
        animation-name: move_1;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        }

      @keyframes move_1 {
        0%,
        100% {
          // opacity: 0;
          transform: translate(0%, 100%);
        }
        25% {
          transform: translate(-100%, 100%);
        }
        50% {
          transform: translate(-100%, 0%);
        }
        75% {
          transform: translate(0%, 0%);
          // opacity: 1;
        }
      }


      img.photo_B {
      position: relative;
        width: 50%;
        animation-name: move_2;
        animation-duration: 8s;
        animation-iteration-count: infinite;
      }

      @keyframes move_2 {
        0%,
        100% {
          // opacity: 0;
          transform: translate(0%, -100%);
        }
        25% {
          transform: translate(100%, -100%);
        }
        50% {
          transform: translate(100%, 0%);
        }
        75% {
          transform: translate(0%, 0%);
          // opacity: 1;
        }
      }

      img.photo_C {
        position: absolute;
        width: 30%;
        top: 10%;
        left: 10%;
        animation-name: move_3;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        }
            @keyframes move_3 {
        0%,
        100% {
          opacity: 0;
          transform: translate(165%, 0%);
        }
        25% {
          transform: translate(165%, 165%);
          opacity: 1;
        }
        50% {
          transform: translate(0%, 165%);
          opacity: 0;
        }
        75% {
          transform: translate(0%, 0%);
          opacity: 1;
        }
      }

      img.photo_D {
        position: absolute;
        width: 30%;
        bottom: 10%;
        right: 10%;
        animation-name: move_4;
        animation-duration: 8s;
        animation-iteration-count: infinite;
      }
            @keyframes move_4 {
        0%,
        100% {
          opacity: 0;
          transform: translate(-165%, 0%);
        }
        25% {
          transform: translate(-165%, -165%);
          opacity: 1;
        }
        50% {
          transform: translate(0%, -165%);
          opacity: 0;
        }
        75% {
          transform: translate(0%, 0%);
          opacity: 1;
        }
      }

      .background {
        position: absolute;
        background: #9725EA;
        width: 50%;
        height: 50%;
        left: 25%;
        top: 25%;
      }
          }


  }


  .area03 {
    background-image: url("../images/item07/img_7.jpg");
    .text_box {
      .box_ttl {
        .animation {
          background: #9725EA;
        }
      }
    }
  }
  .area03::after {
    content: '';
    background-color: rgba(151, 37, 234, .3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }


  .area04 {
    .detail {
      width: calc(100% - 80px);
      position: relative;

      .border {
        width: 100%;
        height: 80px;
        background: #9725EA;
        position: absolute;
        left: 0;
        bottom: 35px;
      }

      img {
        height: 235px;
        margin: 0 auto;
        z-index: 10;
        position: relative;
      }
    }

    .select_img {
      width: calc(100% - 80px);
      padding: 80px 80px 0px;
      box-sizing: border-box;
      display: flex;
      position: relative;
      margin-bottom: 200px;
    }

    .select_img p {
      width: 50%;
      margin: 0 10px 0 0;
      border: solid 15px #9725EA;
      box-sizing: border-box;
    }

    .select_img ul {
      width: 50%;
      margin: auto;
      display: block;
      overflow: hidden;
      clear: both;
      padding: 5px;
      box-sizing: border-box;

    }

    .select_img li {
      width: calc(100% / 3);
      display: block;
      float: left;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
    }

    .select_img li img {
      cursor: pointer;
    }

    .select_img li img:hover {
      border: solid 8px #f4b9e0;
      box-sizing: border-box;
    }

    .change_text {
      width: 50%;
      line-height: 26px;
      box-sizing: border-box;
      position: absolute;
      margin: auto;
      right: 0;
      top: 100%;
      padding: 10px 80px 0 20px;
    }

  }

  .area05 {
    width: calc(100% - 80px);
    height: 750px;
    background-size: cover;
    background-image: url(../images/item07/img_8.jpg);
    position: relative;
    margin-bottom: 200px;
    color: #fff;

    p.comment {
      width: 55%;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 80px;
      top: 80px;
      padding: 30px;
      box-sizing: border-box;
    }

    p.co_ttl {
      position: absolute;
      bottom: 90px;
      right: 70px;
    }

    p.co_border {
      left: 80px;
      position: absolute;
      bottom: 80px;
    }
  }

  .area05_2 {
    width: calc(100% - 80px);
    height: 500px;
    background-size: cover;
    background-image: url(../images/item07/img_7.jpg);
    position: relative;
    margin-bottom: 250px;
    color: #fff;
  }

}
//page_item07







// キャンプファイヤリンク
.cheer_box {
  width: calc(100% - 80px);
  height: 100px;
  display: flex;
  margin-bottom: 300px;

  .box_btn {
    width: 40%;
    text-align: right;
    margin-right: 50px;

    .btn-square-slant {
      display: inline-block;
      position: relative;
      font-size: 28px;
      font-weight: 600;
      padding: 30px 40px;
      text-decoration: none;
      background: #f5cd31;
      border-bottom: solid 8px #000;
      border-right: solid 8px #000;
      transition-duration: 0.3s;

      &:hover {
    color: #fff;
        text-shadow: 2px 2px 0px #000;
      }
    }

    .btn-square-slant:before {
      content: " ";
      position: absolute;
      bottom: -8px;
      left: -1px;
      width: 0;
      height: 0;
      border-width: 0 8px 8px 0px;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: #FFF;
    }

    .btn-square-slant:after {
      content: " ";
      position: absolute;
      top: -1px;
      right: -8px;
      width: 0;
      height: 0;
      border-width: 0px 10px 10px 0px;
      border-style: solid;
      border-color: #FFF;
      border-bottom-color: transparent;
    }

    .btn-square-slant:active {
      border: none;
      -webkit-transform: translate(3px, 6px);
      transform: translate(3px, 6px);
      background: #000;
      color: #f5cd31;
    }

    .btn-square-slant:active:after,
    .btn-square-slant:active:before {
      content: none;
    }
  }

  .box_text {
    width: 60%;
    position: relative;
    height: 20px;
    box-sizing: border-box;
    margin-top: 35px;

    p.border_1 {
      position: absolute;
      top: -80px;
      background: #000;
      width: 550px;
      height: 8px;
      transform: rotate(-13deg);
    }
    p {
    padding-left: 60px;
    box-sizing: border-box;
    }
    p.border_2 {
      position: absolute;
      bottom: -80px;
      background: #000;
      width: 550px;
      height: 8px;
      transform: rotate(13deg);
    }
  }
}

// box

.cheer_box_2 {
  width: calc(100% - 80px);
  height: 100px;
  display: flex;
  margin-bottom: 300px;

  .box_text {
    height: 20px;
    width: 55%;
    position: relative;
    text-align: right;
    top: 40px;

    p.border_1 {
      position: absolute;
      top: -100px;
      right: 0;
      background: #000;
      width: 550px;
      height: 8px;
      transform: rotate(15deg);
    }

    p.border_2 {
      position: absolute;
      right: 0;
      bottom: -100px;
      background: #000;
      width: 550px;
      height: 8px;
      transform: rotate(-15deg);
    }
  }

   .box_btn {
     width: 45%;
     text-align: center;
     margin-right: 50px;

     .btn-square-slant {
       display: inline-block;
       position: relative;
       font-size: 28px;
       font-weight: 600;
       padding: 30px 40px;
       text-decoration: none;
       background: #f5cd31;
       border-bottom: solid 8px #000;
       border-right: solid 8px #000;
       transition-duration: 0.3s;

       &:hover {
         color: #fff;
         text-shadow: 2px 2px 0px #000;
       }
     }

     .btn-square-slant:before {
       content: " ";
       position: absolute;
       bottom: -8px;
       left: -1px;
       width: 0;
       height: 0;
       border-width: 0 8px 8px 0px;
       border-style: solid;
       border-color: transparent;
       border-bottom-color: #FFF;
     }

     .btn-square-slant:after {
       content: " ";
       position: absolute;
       top: -1px;
       right: -8px;
       width: 0;
       height: 0;
       border-width: 0px 10px 10px 0px;
       border-style: solid;
       border-color: #FFF;
       border-bottom-color: transparent;
     }

     .btn-square-slant:active {
       border: none;
       -webkit-transform: translate(3px, 6px);
       transform: translate(3px, 6px);
       background: #000;
       color: #f5cd31;
     }

     .btn-square-slant:active:after,
     .btn-square-slant:active:before {
       content: none;
     }
   }
}

//キャンプファイヤリンク


// ページトップ戻りボタン
#page-top {
  position: fixed;
  bottom: 100px;
  right: 100px;
  font-size: 35px;
      z-index: 1000;
}

#page-top a {
  background: #000;
  text-decoration: none;
  color: #fff;
  width: 80px;
  padding: 20px 0;
  text-align: center;
  display: block;
    transition-duration: 0.4s;
}

#page-top a:hover {
  text-decoration: none;
  background: #ffeb00;
}

#copyright {
    position: absolute;
    bottom: 10px;
    right: 90px;
    font-size: 11px;
}


.change {
  position: absolute;
  cursor: none;
  bottom: 0;
  width: 200px;
  height: 200px;
}

.change img {
  position: fixed;
  visibility: hidden;
  opacity: 0;
}

.change.on_mouse img {
  visibility: visible;
  opacity: 1;
}
